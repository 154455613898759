import styled from 'styled-components';
import { Swiper } from 'swiper/react';

export const CarouselContainer = styled(Swiper)`
  width: 100%;
  height: max-content;
`;

export const CarouselPrevButton = styled.div`
  position: absolute;
  z-index: 2;
  width: 3rem;
  height: 3rem;
  top: 50%;
  bottom: 50%;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(var(--color-primary));
  cursor: pointer;

  & > svg {
    z-index: 3;
  }

  @media screen and (min-width: 37.5em) {
    width: 4rem;
    height: 4rem;

    & > svg {
      width: 2rem;
      height: 2rem;
    }
  }

  @media screen and (min-width: 53.125em) {
    width: 5rem;
    height: 5rem;

    & > svg {
      width: 2.4rem;
      height: 2.4rem;
    }
  }
`;

export const CarouselNextButton = styled.div`
  position: absolute;
  z-index: 2;
  top: 50%;
  bottom: 50%;
  right: 0;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(var(--color-primary));
  cursor: pointer;


  & > svg {
    z-index: 3;
  }

  @media screen and (min-width: 37.5em) {
    width: 4rem;
    height: 4rem;

    & > svg {
      width: 2rem;
      height: 2rem:
    }
  }

  @media screen and (min-width: 53.125em) {
    width: 5rem;
    height: 5rem;

    & > svg {
      width: 2.4rem;
      height: 2.4rem;
    }
  }
`;
