import { ReactNode, Children, FC } from 'react';
import { SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

import {
  CarouselContainer,
  CarouselNextButton,
  CarouselPrevButton,
} from './carousel.style';
import { SvgIcon } from '@gate-academy/react';

interface ICarouselProps {
  children: ReactNode;
}

const Carousel: FC<ICarouselProps> = ({ children }) => {
  return (
    <CarouselContainer
      slidesPerView={1}
      navigation={{ nextEl: '.custom-next-btn', prevEl: '.custom-prev-btn' }}
      modules={[Navigation, Autoplay]}
      autoplay={{
        delay: 5000,
        disableOnInteraction: true,
        pauseOnMouseEnter: true,
      }}
      loop
    >
      {Children.map(children, (child, index) => {
        return <SwiperSlide key={index}>{child}</SwiperSlide>;
      })}

      <CarouselPrevButton className="custom-prev-btn">
        <SvgIcon iconName="arrow-left"></SvgIcon>
      </CarouselPrevButton>
      <CarouselNextButton className="custom-next-btn">
        <SvgIcon iconName="arrow-right2"></SvgIcon>
      </CarouselNextButton>
    </CarouselContainer>
  );
};

export default Carousel;
